import React from "react"
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react"

function ManualForm({ formData, handleBlur, handleChange, errors }) {
  return (
    <form noValidate>
      <FormControl id="title" isRequired mt={4} isInvalid={errors["title"]}>
        <FormLabel>Title</FormLabel>
        <Input
          name="title"
          placeholder="Enter the title"
          type="text"
          size={"md"}
          borderWidth="1px"
          bg={formData.title ? "white" : "gray.50"}
          borderColor="gray.50"
          borderRadius="md"
          value={formData.title}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <FormErrorMessage>{errors["title"]}</FormErrorMessage>
      </FormControl>
      <FormControl
        id="description"
        isRequired
        mt={4}
        isInvalid={errors["description"]}
      >
        <FormLabel>Description</FormLabel>
        <Textarea
          name="description"
          size={"md"}
          borderWidth="1px"
          placeholder="Enter the description"
          bg={formData.description ? "white" : "gray.50"}
          borderColor="gray.50"
          borderRadius="md"
          value={formData.description}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <FormErrorMessage>{errors["description"]}</FormErrorMessage>
      </FormControl>
    </form>
  )
}

export default ManualForm
