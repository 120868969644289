import axios from "axios"

const api = axios.create({
  baseURL: process.env.GATSBY_API_BASE_URL, // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
})

api.interceptors.request.use(
  config => {
    // Retrieve the token from your authentication system
    const token = localStorage.getItem(
      "realm-web:app(chatvz-ydmzg):user(642d302b390b738de18b8ec6):accessToken"
    )
    if (token) {
      // Add the authentication header to the request
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        const refreshToken = localStorage.getItem("refresh_token")
        const response = await api.post("/refresh_token", {
          refresh_token: refreshToken,
        })

        const newAccessToken = response.data.access_token
        localStorage.setItem("access_token", newAccessToken)

        // Update the authorization header with the new access token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`

        return api(originalRequest)
      } catch (refreshError) {
        // If refresh token request fails or returns a 401, log out the user and redirect to login
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        // Redirect to login page
      }
    }

    return Promise.reject(error)
  }
)

export default api
